import React, { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import ReactPlayer from 'react-player';

import ArmenianFlag from "../../assets/images/Armenia.png";
import UkFlag from "../../assets/images/UK.png";
import RussianFlag from "../../assets/images/russia.png";

import VideoUpload from "../VideoUpload/Index";
import UpgradePlan from "../../modals/UpgradePlan";
import SubtitleBack from "../../modals/SubtitleBack"

import { io } from "socket.io-client";

import { toast } from "react-toastify";
import { Button, Input, Progress } from "antd";

import { DownloadOutlined, ArrowLeftOutlined  } from '@ant-design/icons';

import Switch from "react-switch";

import "./Index.scss";

const { TextArea } = Input;

function Subtitle() {

  const {
    getSubscriptionPlanName
  } = useSelector(state => state.auth)

  const languageData = [
    { id: 1, languageName: 'Armenian', languageFlag: ArmenianFlag, languageSlug: 'HY' },
    { id: 2, languageName: 'English', languageFlag: UkFlag, languageSlug: 'EN' },
    { id: 3, languageName: 'Russian', languageFlag: RussianFlag, languageSlug: 'RU' }
  ];
  const defaultSelected = languageData[0];
  const socketRef = useRef(null);
  const token = localStorage.token;

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [progress, setProgress] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(defaultSelected);
  const [isWordHighlighted, setIsWordHighlighted] = useState(true);
  const [isFileReset, setIsFileReset] = useState(false);
  const [isDataShown, setIsDataShown] = useState(true);
  const [videoUrl, setVideoUrl] = useState('');
  const [processId, setProcessId] = useState('');
  const [maxLength, setMaxLength] = useState(0);
  const [minLength, setMinLength] = useState(0);
  const [transcribedValue, setTranscribedValue] = useState('');
  const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(false);
  const [subscriptionLimitErrorMessage, setSubscriptionLimitErrorMessage] = useState('');
  const [isBackModalOpen, setIsBackModalOpen] = useState(false);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);

  useEffect(() => {

    const handleBeforeUnload = (event) => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
    }
  }, [])

  function fileUpload(videoBlob) {
    setIsLoading(true);
    setIsDataShown(false);
    sendVideoBlob(videoBlob);
  }

  function sendVideoBlob(videoBlob) {
    const { languageSlug } = selectedLanguage;
    const dataToSend = {
      token,
      video_data: videoBlob,
      language: languageSlug,
    };

    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {timeout: 86400, reconnectionDelay: 14400, reconnectionDelayMax: 43200});

    socketRef.current.on('connect', () => {
      socketRef.current.emit('generate_subtitle', dataToSend);
      setSubscriptionLimitErrorMessage('');
    });

    socketRef.current.on('transcribe_error', (data) => {
      toast.error(data.message)
    });

    socketRef.current.on('limit_error', (data) => {
      toggleUpgradePlanModalOpen(true)
      setSubscriptionLimitErrorMessage(data.message);
    });

    socketRef.current.on('transcribe_progress', (data) => {
      const percent = +data.percent.toFixed(2);
      setProgress(percent);
      setLoadingText(data.info_text);
    });

    socketRef.current.on('subtitle_result', (data) => {
      setProcessId(data.process_id);
      setTranscribedValue(data.transcription);
      const blobUrl = URL.createObjectURL(videoBlob);
      setVideoUrl(blobUrl);
      const maxData = (Math.round(data.transcription.length * 0.3) + data.transcription.length);
      setMaxLength(maxData);
      const minData = (Math.round(data.transcription.length * 0.3));
      setMinLength(minData);
    })

    socketRef.current.on('disconnect', () => {
      setTimeout(() => {
        setIsLoading(false);
        setLoadingText('');
        setProgress(0);
        fileReset(true);
        socketRef.current.disconnect();
      }, 1000)
    });
  }

  function sendTranscription() {
    setIsLoading(true);
    const { languageSlug } = selectedLanguage;
    const dataToSend = {
      token,
      process_id: processId,
      language: languageSlug,
      transcription: transcribedValue,
      is_spoken_highlighted: isWordHighlighted,
    };

    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {timeout: 86400, reconnectionDelay: 14400, reconnectionDelayMax: 43200});

    socketRef.current.on('connect', () => {
      socketRef.current.emit('finalize_generate_subtitle', dataToSend);
      setTranscribedValue('');
    });

    socketRef.current.on('transcribe_error', (data) => {
      toast.error(data.message)
    });

    socketRef.current.on('subtitle_progress', (data) => {
      const percent = +data.percent.toFixed(2);
      setProgress(percent);
      setLoadingText(data.info_text);
    });

    socketRef.current.on('subtitled_video', (data) => {
      setIsDownloadButtonVisible(true);
      const blob = new Blob([data.video_data], { type: 'video/mp4' }); // Adjust mime type as per your video format
      const videoBlobUrl = URL.createObjectURL(blob);
      setVideoUrl(videoBlobUrl);
    })

    socketRef.current.on('disconnect', () => {
      setTimeout(() => {
        setIsLoading(false);
        setLoadingText('');
        setProgress(0);
        socketRef.current.disconnect();
      }, 1000)
    });
  }

  function changeLanguage(language) {
    setSelectedLanguage(language)
  }

  function changeWordHighlighted(checked) {
    setIsWordHighlighted(checked)
  }

  function fileReset(isReset) {
    setIsFileReset(isReset);
  }

  function onDurationShown(isDuration) {
    if (isDuration) {
      toast.error('Maximum 1 hour');
    }
  }

  function onFormatShown(isFormat) {
    if (isFormat) {
      toast.error('Not supported format (only MP4)');
    }
  }

  function onSizeShown(isSize) {
    if (isSize) {
      toast.error('Maximum 180 MB');
    }
  }

  function handleChange({target: {value} }) {
    setTranscribedValue(value);
  }

  function toggleBackModal(isOpen) {
    setIsBackModalOpen(isOpen);
  }

  function goBack() {
    if (videoUrl) {
      URL.revokeObjectURL(videoUrl);
    }
    setVideoUrl('');
    setTranscribedValue('');
    setIsDataShown(true);
    setIsDownloadButtonVisible(false);
    setIsLoading(false);
  }

  function toggleUpgradePlanModalOpen(isOpen) {
    setIsUpgradePlanModalOpen(isOpen)
  }

  return (
  <div className="subtitle">
    {isDataShown &&
      <>
        <h2 className="subtitle-title">Upload Video to Generate Subtitle<p>(max 5 minutes)</p></h2>
        <div className="subtitle-data">
          <div className="upload">
            <VideoUpload isFileReset={isFileReset} languageData={languageData} selectedLanguage={selectedLanguage} onUpload={fileUpload} onSelectLanguage={changeLanguage} onResetFile={fileReset}
                         onDurationShown={onDurationShown} onFormatShown={onFormatShown} onSizeShown={onSizeShown}
            />
          </div>
        </div>
      </>
    }
    {videoUrl && !isDataShown && !isLoading &&
      <>
        {transcribedValue &&
          <h3 className="subtitle-title">
            Please review the transcription carefully. If there are any incorrect words, make the necessary corrections. Once you're satisfied, submit it to generate the subtitles. If everything looks correct, simply submit it as is.
          </h3>
        }
        {isDownloadButtonVisible &&
          <h3 className="subtitle-title">
            Your video with subtitles has been successfully generated! You can now play the video to review it or download it directly.
          </h3>
        }
        <div className="subtitle-video-player">
          <ReactPlayer url={videoUrl} controls />
        </div>
        {transcribedValue &&
          <div className="subtitle-transcribed-text">
            <div className="subtitle-transcribed-filters">
              <div className="react-switch">
                <Switch onChange={changeWordHighlighted} checked={isWordHighlighted} />
                <span onClick={() => changeWordHighlighted(!isWordHighlighted)}>Highlight spoken word</span>
              </div>
              <Button
                type="primary"
                size="large"
                className="subtitle-finish-button"
                onClick={sendTranscription}
              >
                Finish
              </Button>
            </div>
            <TextArea
              placeholder="Transcribed text"
              value={transcribedValue}
              onChange={handleChange}
              autoSize={{ minRows: 1, maxRows: 7 }}
              minLength={minLength}
              maxLength={maxLength}
            />
            <div className="subtitle-back-section">
              <Button
                type="primary"
                size="large"
                icon={<ArrowLeftOutlined className="button-icons" />}
                className="subtitle-back-button"
                onClick={() => toggleBackModal(true)}
              >
                Back
              </Button>
            </div>
          </div>
        }
        {isDownloadButtonVisible &&
          <div className="subtitle-download-section">
            <a href={videoUrl} download>
              <Button
                type="primary"
                size="large"
                icon={<DownloadOutlined className="button-icons" />}
                className="subtitle-download-button"
              >
                Download Video
              </Button>
            </a>
            <div className="subtitle-back-section">
              <Button
                type="primary"
                size="large"
                icon={<ArrowLeftOutlined className="button-icons" />}
                className="subtitle-back-button"
                onClick={goBack}
              >
                Back
              </Button>
            </div>
          </div>
        }
      </>
    }
    {isLoading ?
      <div className="subtitle-loader-container">
        <h3 className="subtitle-title">{loadingText}</h3>
        <Progress type="circle" percent={progress} />
      </div> : null
    }
    {isBackModalOpen && <SubtitleBack isBackModalOpen={isBackModalOpen} onBack={goBack} onClose={toggleBackModal} />}
    <UpgradePlan isUpgradePlanModalOpen={isUpgradePlanModalOpen} currentSubscriptionPlan={getSubscriptionPlanName} subscriptionLimitErrorMessage={subscriptionLimitErrorMessage} onClose={toggleUpgradePlanModalOpen} />
  </div>
  )
}

export default Subtitle;