import React, { useEffect, useRef, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AudioRecorder from "../AudioRecorder/Index";
import UpgradePlan from "../../modals/UpgradePlan";
import LanguageDropdown from "../LanguageDropdown/Index";
import usePrevious from "../../hooks/usePrevious";
import Switch from "react-switch";

import {
  currentDocumentRequest,
  downloadDocDocumentRequest,
  downloadPdfDocumentRequest,
  updateDocumentRequest,
  updateDocumentTitleRequest
} from "../../redux/Document/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';

import { Input } from 'antd';
import { BsFiletypeDocx } from "react-icons/bs";
import { FaRegFilePdf } from "react-icons/fa6";
import { FaRegSave  } from "react-icons/fa";

import ArmenianFlag from "../../assets/images/Armenia.png";
import UkFlag from "../../assets/images/UK.png";
import RussianFlag from "../../assets/images/russia.png";

import { io } from "socket.io-client";
import { toast } from 'react-toastify';
import Loader from "../Loader";

import "./Details.scss"

const DocumentDetails = () => {
  const {
    getSubscriptionPlanName
  } = useSelector(state => state.auth)
  const {
    isUpdateDocumentSuccess,
    isCurrentDocumentSuccess, currentDocument,
    isDownloadPdfDocumentSuccess, isDownloadPdfDocumentError, downloadPdfDocumentErrorMessage, downloadPdfDocument,
    isDownloadDocDocumentSuccess, isDownloadDocDocumentError, downloadDocDocumentErrorMessage, downloadDocDocument,
  } = useSelector(state => state.document);
  const {id} = useParams();
  const dispatch = useDispatch()
  const quillRef = useRef();
  const socketRef = useRef(null);
  const setTimeoutRef = useRef(null);
  const prevIsDownloadPdfDocumentSuccess = usePrevious(isDownloadPdfDocumentSuccess);
  const prevIsDownloadDocDocumentSuccess = usePrevious(isDownloadDocDocumentSuccess);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{'header': 1}, {'header': 2}],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'script': 'sub'}, {'script': 'super'}],
      [{'color': []}],
      ['clean']
    ]
  };

  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'header',
    'list', 'bullet',
    'script', 'sub', 'super',
    'color',
    'clean'
  ];

  const languageData = [
    { id: 1, languageName: 'Armenian', languageFlag: ArmenianFlag, languageSlug: 'HY' },
    { id: 2, languageName: 'English', languageFlag: UkFlag, languageSlug: 'EN' },
    { id: 3, languageName: 'Russian', languageFlag: RussianFlag, languageSlug: 'RU' }
  ];
  const defaultSelected = languageData[0];

  const [selectedLanguage, setSelectedLanguage] = useState(defaultSelected);
  const [selectedFilters, setSelectedFilters] = useState({is_punct_capit: false})
  const [titleValue, setTitleValue] = useState('')
  const [prevTitleValue, setPrevTitleValue] = useState('')
  const [editorHtml, setEditorHtml] = useState('');
  const [downloadType, setDownloadType] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [isUpgradePlanModalOpen, setIsUpgradePlanModalOpen] = useState(false);
  const [subscriptionLimitErrorMessage, setSubscriptionLimitErrorMessage] = useState('');

  useEffect(() => {
    if (id) {
      dispatch(currentDocumentRequest(id))
    }

    const handleBeforeUnload = (event) => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    }
  }, [])

  useEffect(() => {
    if (getSubscriptionPlanName && isCurrentDocumentSuccess) {
      setIsLoader(false);
    }
  }, [getSubscriptionPlanName, isCurrentDocumentSuccess])

  useEffect(() => {
    if (isCurrentDocumentSuccess && !isLoader) {
      setEditorHtml(currentDocument.document_content);
      setTitleValue(currentDocument.document_title);
      setPrevTitleValue(currentDocument.document_title);
      const quill = quillRef.current.getEditor();
      quill.focus();
    }
  }, [isCurrentDocumentSuccess, isLoader])

  useEffect(() => {
    if (isCurrentDocumentSuccess && currentDocument.document_content) {
      if (setTimeoutRef.current) {
        clearTimeout(setTimeoutRef.current)
      }

      setTimeoutRef.current = setTimeout(() => {
        updateDocument();
      }, 1500)
    }
  }, [editorHtml, isCurrentDocumentSuccess])

  useEffect(() => {
    if (downloadType && ((isDownloadPdfDocumentSuccess && prevIsDownloadPdfDocumentSuccess === false) || (isDownloadDocDocumentSuccess && prevIsDownloadDocDocumentSuccess === false))) {
      downloadDocument(
        downloadType === 'pdf' ? downloadPdfDocument : downloadDocDocument,
        downloadType === 'pdf' ? "application/pdf" : "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      );
      setDownloadType(null); // Reset after download
    }
  }, [isDownloadPdfDocumentSuccess, isDownloadDocDocumentSuccess]);

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  const updateDocument = () => {
    const content = editorHtml.toString();
    dispatch(updateDocumentRequest({id, body: {raw_data: content}}))
  }

  const insertTextAtCursor = (text) => {
    const quill = quillRef.current.getEditor();
    const selection = quill.getSelection();
    if (!selection) {
      setCursorToEnd();
    }
    const position = quill.getSelection()
    quill.insertText(position.index, text + ' ');
  };

  const setCursorToEnd = () => {
    const quill = quillRef.current.getEditor();
    const length = quill.getLength();
    quill.setSelection(length);
  };

  function changeLanguage(language) {
    setSelectedLanguage(language)
  }

  function inputChangeHandler(checked) {
    setSelectedFilters({...selectedFilters, is_punct_capit: checked})
  }

  function convertTo(type) {
    dispatch(type === 'pdf' ? downloadPdfDocumentRequest(id) : downloadDocDocumentRequest(id));
    setDownloadType(type);
  }

  function downloadDocument(downloadLink, downloadType) {
    const documentBlob = new Blob([downloadLink], {type: downloadType});
    const url = window.URL.createObjectURL(documentBlob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = titleValue;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url)
  }

  function titleValueChange({target: {value}}) {
    setTitleValue(value)
  }

  function titleValueBlur({target: {value}}) {
    if (!titleValue.trim() && prevTitleValue.trim()) {
      setTitleValue(prevTitleValue);
    } else if (titleValue.trim() !== prevTitleValue.trim()) {
      setPrevTitleValue(value.trim())
      dispatch(updateDocumentTitleRequest({id, body: {document_title: value.trim()}}))
    }
  }

  function sendAudioBlob(audioBlob) {
    const token = localStorage.token;
    const is_upload = false;
    const { is_punct_capit } = selectedFilters;
    const { languageSlug } = selectedLanguage;
    const dataToSend = {
      token,
      audio_data: audioBlob,
      is_punct_capit,
      language: languageSlug,
      is_upload,
    };

    // socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
    socketRef.current = io(process.env.REACT_APP_SOCKET_URL, {timeout: 86400, reconnectionDelay: 14400, reconnectionDelayMax: 43200});

    socketRef.current.on('connect', () => {
      socketRef.current.emit('start_transcribe', dataToSend);
      setSubscriptionLimitErrorMessage('');
    });

    socketRef.current.on('transcribe_error', (data) => {
      toast.error(data.message)
    });

    socketRef.current.on('limit_error', (data) => {
      toggleUpgradePlanModalOpen(true)
      setSubscriptionLimitErrorMessage(data.message);
    });

    socketRef.current.on('transcribe_progress', (data) => {
      if (data.percent === -1) {
        toast.error(data.transcription)
      } else {
        const percent = +data.percent.toFixed(2);
        setProgress(percent);
        data.transcription && insertTextAtCursor(data.transcription);
      }
    });

    socketRef.current.on('disconnect', () => {
      setTimeout(() => {
        setProgress(0);
        toggleLoader(false);
        socketRef.current.disconnect();
      }, 1000)
    });
  }
  function toggleLoader(isLoad) {
    setIsLoading(isLoad);
  }

  function toggleUpgradePlanModalOpen(isOpen) {
    setIsUpgradePlanModalOpen(isOpen)
  }

  return (
    <>
      {isLoader ?
        <Loader fullWidth /> :
        <div className="document-details">
          <div className="container">
            <ReactQuill
              ref={quillRef}
              theme="snow"
              className="editor"
              modules={modules}
              formats={formats}
              value={editorHtml}
              onChange={handleChange}
            />
          </div>
          <div className="sidebar">
            {
              !isUpdateDocumentSuccess &&
              <div className="save-container">
                <FaRegSave className="save-icon"/>
                <p>Saving...</p>
              </div>
            }
            <div className="sidebar-top">
              <div className="document-title">
                <Input placeholder="Title" value={titleValue} onChange={titleValueChange} onBlur={titleValueBlur} maxLength="36"/>
              </div>
              <div className="filter">
                <div className="language-drop-down">
                  <LanguageDropdown items={languageData} defaultSelected={selectedLanguage} onChangeItem={changeLanguage}/>
                </div>
                <div className="react-switch">
                  <span onClick={() => inputChangeHandler(!selectedFilters.is_punct_capit)}>Grammar</span>
                  <Switch onChange={inputChangeHandler} checked={selectedFilters.is_punct_capit}/>
                </div>
              </div>
            </div>
            <>
              <AudioRecorder selectedLanguage={selectedLanguage} selectedFilters={selectedFilters} progress={progress} isLoading={isLoading} toggleLoader={toggleLoader} sendAudioBlob={sendAudioBlob} />
            </>
            <div className="sidebar-bottom">
              <div className="download-section">
                <p>Download as:</p>
                <div className="download-icons-container">
                  <FaRegFilePdf className="download-icons pdf-icon" onClick={() => convertTo('pdf')}/>
                  <BsFiletypeDocx className="download-icons doc-icon" onClick={() => convertTo('doc')}/>
                </div>
              </div>
            </div>
          </div>
          <UpgradePlan isUpgradePlanModalOpen={isUpgradePlanModalOpen} currentSubscriptionPlan={getSubscriptionPlanName} subscriptionLimitErrorMessage={subscriptionLimitErrorMessage} onClose={toggleUpgradePlanModalOpen} />
        </div>
      }
    </>
  );
};

export default DocumentDetails;
