import React, { useState, useEffect, useRef } from 'react';

import { Button } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { SendOutlined } from '@ant-design/icons';

import LanguageDropdown from "../LanguageDropdown/Index";

import './Index.scss';

const formats = ['video/mp4', 'video/quicktime']; // Accept only MP4 format

function VideoUpload({ isFileReset, languageData, selectedLanguage, onSelectLanguage, onUpload, onResetFile, onDurationShown, onFormatShown, onSizeShown }) {
  const fileInputRef = useRef(null);

  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    if (isFileReset) {
      setSelectedFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  }, [isFileReset]);

  function handleFileChange({ target }) {
    const videoData = target.files[0];
    const videoBlob = videoData ? new Blob([videoData], { type: videoData.type }) : null;
    if (videoData) {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(videoData);

      const handleDuration = () => {
        const sizeInMB = (videoData.size / (1024 * 1024)).toFixed(2);
        const duration = Math.floor(video.duration);

        if (duration >= 3600) {
          onDurationShown(true); // If video is longer than 1 hour
          setSelectedFile(null);
        } else if (!formats.includes(videoData.type)) {
          onFormatShown(true); // If the format is not .mp4
        } else if (sizeInMB >= 180) {
          onSizeShown(true); // If video size exceeds 180MB
        } else {
          setSelectedFile(videoBlob);
          onResetFile(false);
          onDurationShown(false);
          onFormatShown(false);
          onSizeShown(false);
        }
      };
      video.addEventListener('loadedmetadata', handleDuration);
    } else {
      setSelectedFile(null);
    }
  }

  function changeLanguage(language) {
    onSelectLanguage(language)
  }

  function fileUpload() {
    onUpload(selectedFile);
  }

  return (
    <div className="video-upload">
      <div className="video-upload-container">
        <Button
          type="primary"
          icon={<CloudUploadOutlined className="button-icons" />}
          size="large"
          className="file-buttons"
          onClick={() => fileInputRef.current.click()}
        >
          Upload Video
          <input
            id="file-upload"
            type="file"
            accept="video/mp4, video/quicktime" // Only accept .mp4 format
            onChange={handleFileChange}
            ref={fileInputRef}
            hidden
          />
        </Button>
      </div>
      {selectedFile && (
        <div className="video-upload-start">
          <div className="video-upload-language-drop-down">
            <LanguageDropdown items={languageData} defaultSelected={selectedLanguage} onChangeItem={changeLanguage} />
          </div>
          <Button
            type="primary"
            icon={<SendOutlined className="button-icons" />}
            size="large"
            className="file-buttons send-button"
            onClick={fileUpload}
          >
            Send
          </Button>
        </div>
      )}
    </div>
  );
}

export default VideoUpload;
