import React from "react";
import { Modal} from "antd";

import "./index.scss";

function SubtitleBack({ isBackModalOpen, onBack, onClose }) {

  function goBack() {
    onBack();
    closeModal();
  }

  function closeModal() {
    onClose(false);
  }

  return (
    <>
      <Modal
        className="confirm-subtitle-back-modal"
        title={<h3 className="title">If you proceed with this action, the generated transcriptions for the video will be lost, but the seconds already deducted from your subscription plan will not be refunded.</h3>}
        open={isBackModalOpen}
        onCancel={closeModal}
        okText="Yes"
        onOk={goBack}
        centered
      >
      </Modal>
    </>
  )
}

export default SubtitleBack